/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body,
html {
  font-size: 20px;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
  color: #212b36;
  box-sizing: border-box;
  background: #f4f6f8;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast-container {
  width: auto !important;
  /* margin: 10px !important; */
}
.Toastify__toast {
  min-height: unset !important;
  border-radius: 6px !important;
  padding: 15px 15px !important;
  /* margin: 10px !important; */
}
.Toastify__toast--success {
  background-color: #bbebae !important;
  color: #212b36 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  width: auto !important;
  margin-top: 10px !important;
}
.Toastify__toast--error {
  background-color: #eb8380 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  width: auto !important;
  margin-top: 10px !important;
}
.Toastify__toast--warning {
  background-color: #f8d971 !important;
  color: #212b36 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  width: auto !important;
  margin-top: 10px !important;
}

.Toastify__toast-container--top-center {
  top: unset !important;
  left: unset !important;
  margin-left: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  flex-direction: column !important;
  padding-top: 10px !important;
  padding: 0 10px !important;
}

.toast-close svg {
  width: 11px;
  height: 11px;
}
.toast-close svg path {
  fill: #637381;
}
.toast-close,
.toast-success-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.toast-success-icon,
.toast-danger-icon,
.toast-warning-icon {
  margin-right: 16px;
  display: inline-flex;
}
.toast-success-icon svg path {
  fill: #108043;
}
.toast-danger-icon svg path {
  fill: #bf0711;
}
.toast-warning-icon svg path {
  fill: #9c6f19;
}
.Toastify__toast--error .toast-close svg path {
  fill: #ffffff;
}

button {
  outline: none;
}
