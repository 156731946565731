.rc-steps-item-tail:after {
  height: 100%;
  width: 2px !important;
  background-color: #e9e9e9 !important;
}
.rc-steps-vertical .rc-steps-item-tail {
  padding: 26px 0 0px 0;
  left: 8px;
  padding-top: 0;
}
.rc-steps-vertical .rc-steps-item {
  display: flex !important;
}

.rc-steps-item-finish .rc-steps-item-title,
.rc-steps-item-wait .rc-steps-item-title {
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.rc-steps-item-icon {
  margin-right: 13px;
}
.rc-steps-item-content,
.rc-steps-item-description {
  width: 100%;
  font-size: 14px;
}

.rc-steps-item-title {
  display: none;
}
.rc-steps-item-content {
  margin-top: 0;
}

.rc-steps-item-finish .rc-steps-item-description {
  color: #212b36;
}
