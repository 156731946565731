.pagination {
  display: inline-flex;
}
.pagination li {
  border: 1px solid #5b6ac4;
  cursor: pointer;
  border-left: none;
  color: #637381;
  font-weight: 400;
  font-size: 14px;
  display: inline-flex;
  height: 35px;
  width: 35px;
  font-weight: 600;
  color: #5a68c3;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.pagination li a {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 5px 10px;
}
.pagination li > a {
  outline: white;
}
.pagination li:nth-child(2) {
  border-left: 1px solid #5b6ac4;
  border-radius: 3px 0 0 3px;
}
.pagination li:nth-last-child(2) {
  border-radius: 0 3px 3px 0;
}
.pagination .previous {
  margin-right: 20px;
  border-left: 1px solid #5b6ac4;
  border-radius: 4px;
  width: auto;
}
.pagination .next {
  margin-left: 20px;
  border-left: 1px solid #5b6ac4;
  border-radius: 4px;
  width: auto;
}

.active-number {
  color: #ffff !important;
  background-color: #5b6ac4;
}
.pagination .next a,
.pagination .previous a {
  padding: 5px 10px;
}
